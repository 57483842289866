import React from 'react'
import Navbar from '../../components/navbar'
import BlogMain from '../../../static/images/insurance.jpg'
import USM2 from '../../../static/images/usmone.png'
import USM1 from '../../../static/images/usmtwo.png'


export default function Usm() {
  return (
    <div>
       <Navbar/>
<section class=" pb-16 lg:pb-24 bg-white" style={{fontFamily:'poppins'}}>
  <div class="h-64 lg:h-144">
    <img class="w-full h-full object-cover" src={BlogMain} alt=""/>
  </div>
  <div class="relative container px-4 mx-auto -mt-24">
    <div class="max-w-5xl px-4 pt-12 lg:pt-20 mx-auto bg-gray-100">
      <div class="max-w-2xl mb-12 mx-auto text-center">
        {/* <div class="flex items-center justify-center mb-6 text-xs text-blue-500 font-semibold uppercase">
          <a href="#">Home</a>
          <span class="inline-block mx-2">
            <svg class="text-blue-500 h-3 w-3" viewbox="0 0 8 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.875 0L0 1.875L4.125 6L0 10.125L1.875 12L7.875 6L1.875 0Z"></path>
            </svg>
          </span>
          <a href="#">Blog</a>
          <span class="inline-block mx-2">
            <svg class="text-blue-500 h-3 w-3" viewbox="0 0 8 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.875 0L0 1.875L4.125 6L0 10.125L1.875 12L7.875 6L1.875 0Z"></path>
            </svg>
          </span>
          <a href="#">Article</a>
        </div> */}
        <h2 class="mb-6 text-3xl lg:text-4xl text-indigo-800 font-bold font-heading">Underwriting Scoring Models</h2>
        <div class="flex items-center justify-center">
          {/* <div class="mr-6">
            <img class="w-14 h-14 object-cover" src="https://images.unsplash.com/photo-1607746882042-944635dfe10e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1050&amp;q=80" alt=""/>
          </div> */}
          <div class="text-left">
            {/* <h3 class="mb-2 text-lg text-gray-500 font-semibold font-heading">Sara Sampton</h3> */}
            <p class="text-blue-500 text-xs text-center">Published On November 25, 2021 | Vikram Arora</p>
          </div>
        </div>
      </div>
      <div class="max-w-3xl mx-auto -mb-6 ">
        <p class="mb-6  text-md lg:text-lg text-gray-600">Insurance is a competitive business and Underwriting plays an important role for insurers to stay ahead in the market. There is a growing use of scoring systems in pricing and underwriting work within an Insurance company. The focus is on better marketing of high volume and low premium insurance business. Due to intense competition, there is a reduction of value across the sector. There is a tremendous pressure on business leaders to improve the underwriting performance in order to boost competitiveness and maximise profits.
</p>

<img class="w-full h-full pb-6" src={USM2} alt=""/>

        <p class="mb-6  text-md lg:text-lg text-gray-600">Last 2 years, Insurance was among the industries which is hit by the corona pandemic across the world and industry leaders are looking to find innovative ways to handle it. Many Insurance companies are striving to promote underwriting excellence and price sophistication to handle the increased pressure of huge losses. Insurers are integrating the prices with other elements of their customer value proposition, while remaining nimble in response to changes in customer behavior, competitor actions, and environmental factors.

</p>
        <p class="mb-6 pb-10  text-md lg:text-lg text-gray-600">Underwriting scoring system are considered to be relatively new. With the advancements in the cloud technology and services, it is easy to manage a web application by in house IT team which can provide insights on new proposals while benchmarking them against the overall performance of the specific line of business or overall company performance. With the help of AI enabled systems, it is now possible to look ahead and decide if a particular proposal is a good fit to the portfolio or not. We can look at the granular information and build advanced data and analytics underwriting capabilities that can deliver substantial value. It is possible to anticipate by using the power of data and analytics to proactively assess and identify market opportunities ahead of competition.
</p>
<p class="mb-6 pb-10  text-md lg:text-lg text-gray-600">At RJ Actuaries and Consultants, we work with our clients to use data and advanced analytics to reimagine risk evaluation, improve the customer experience, and enhance efficiency and decision making throughout the underwriting process. We have built underwriting scoring applications for our clients which can be used for loss prevention by taking data from diverse domains for insurance business. We have built agile capabilities to obtain, test, maintain, use, and reuse the data in our machine learning models which are controlled via simple user interface on any internet enabled browser.


</p>

<img class="w-full h-full pb-6" src={USM1} alt=""/>

<p class="mb-6 pb-10  text-md lg:text-lg text-gray-600">We believe that data is the raw material that is unlocking the value of artificial intelligence in insurance business. We have developed sophisticated tech stacks that enable efficient model development and continual revisions. Our analytics teams are deploying descriptive, predictive, and prescriptive models that employ the latest techniques and workbenches.
</p>
<p class="mb-6 pb-10  text-md lg:text-lg text-gray-600"><p class="mb-6 pb-10  text-md lg:text-lg text-gray-600">We believe that data is the raw material that is unlocking the value of artificial intelligence in insurance business. We have developed sophisticated tech stacks that enable efficient model development and continual revisions. Our analytics teams are deploying descriptive, predictive, and prescriptive models that employ the latest techniques and workbenches.
</p>
</p>
       </div>
    </div>
  </div>
</section>



    </div>
  )
}
